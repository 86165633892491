import { Box, Button, styled, Typography } from '@material-ui/core'
import ReactPlayer from 'react-player'
import NoSSR from 'react-no-ssr'
import KidsCornerImg from '../../images/KidsKorner/kids-corner.png'

const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1400)]: {
        width: '100%',
      },
    }
  }}
  padding: 1rem;
  padding-top: 0.5rem;
  width: 85%;
  margin: auto;
`

const KidsCornerContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column-reverse',
      },
    }
  }}
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`
const StyledKidsCornerImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1600)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1400)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1230)]: {
        height: '400px',
      },
      [theme.breakpoints.down(1200)]: {
        height: '320px',
        marginLeft: '1rem',
      },
    }
  }}
  height: 650px;
  object-fit: cover;
`
const KidsCornerTextContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        marginLeft: '6.5%',
      },
      [theme.breakpoints.down(1400)]: {
        marginLeft: '6%',
      },
      [theme.breakpoints.down(1230)]: {
        marginLeft: '6%',
      },
      [theme.breakpoints.down(1200)]: {
        margin: 'auto',
        marginLeft: '1rem',
        width: '95%',
      },
    }
  }}
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 9%;
  width: 80%;
`
const KidsCornerTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down(1650)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.down(1230)]: {
        fontSize: '0.8rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '0.9rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}

  font-size: 1.2rem;
  color: #3c3835;
  margin-bottom: 2rem;
`
const KidsCornerLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.4rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: #008174;
  margin-bottom: 2rem;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`

export default function KidsCorner() {
  return (
    <>
      <KidsCornerContainer>
        <KidsCornerTextContainer>
          <KidsCornerLabelTypography>Kids Korner</KidsCornerLabelTypography>
          <KidsCornerTypography>
            It’s important to us that kids have fun. It’s even better if they
            learn while doing so. That’s why we have created Kids Korner.
          </KidsCornerTypography>
          <KidsCornerTypography>
            Kids Korner aims to compliment the game of Tri Tag Rugby for young
            children by providing key nutritional information, tips on what to
            eat before and after a match, and a clear and simple breakdown of
            how Tri Tag Rugby is played.
          </KidsCornerTypography>
          <KidsCornerTypography>
            We also know, in order to get more than 3 seconds of their
            attention, you need to speak their language. That’s why all key
            messaging is delivered by our favourite animated Groodle and
            Kangaroo, Tri Taggers Timmy and Tammy.
          </KidsCornerTypography>
          <KidsCornerTypography>
            Parents can also access healthy snack and lunchbox ideas for their
            Children.
          </KidsCornerTypography>
          <KidsCornerTypography sx={{ marginBottom: '1rem' }}>
            Download the official Tri Tag Rugby app now.
          </KidsCornerTypography>
        </KidsCornerTextContainer>

        <StyledKidsCornerImg
          src={KidsCornerImg}
          alt="logo"
          placeholder="blurred"
        />
      </KidsCornerContainer>
      <StyledBox>
        <NoSSR>
          <Box sx={{ margin: 'auto', marginTop: '1rem' }}>
            <ReactPlayer
              style={{ margin: 'auto' }}
              controls
              loop
              width="100%"
              height="100%"
              url="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/kids-korner-rules.mp4"
            />
          </Box>
        </NoSSR>
      </StyledBox>
    </>
  )
}
