import { Box } from '@material-ui/core'
import KidsCorner from './kids-corner'

export default function KidsCornerContent() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: '0.1rem' }}
      />
      <KidsCorner />
    </>
  )
}
